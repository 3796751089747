<template>
  <div>
    <input type="file" class="hidden" ref="updateImgProfile" @change="selectImage" accept="image/*">
    <div v-if="imageUrl" style="width: 100%; min-height: 400px">
      <img ref="image" :src="imageUrl" alt="Image to crop" />
      <div class="flex justify-between">
        <vs-button class="mt-3" style="height: 38px" color="secondary" type="border"
        @click="close">{{ $t('Cancel') }}</vs-button>
        <vs-button class="mt-3 hover:bg-primary" :class="{ 'is-loading': isSaving }"
        @click="cropImage">{{ $t('Crop & Upload Image') }}</vs-button>
      </div>

    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';

export default {
  data() {
    return {
      imageUrl: null,
      cropper: null,
      isSaving: false,
    };
  },
  props: {
    imageCropper: {
      default: () => null,
    },
  },
  watch: {
  },
  mounted() {
    if (this.imageCropper) {
      this.onFileChange(this.imageCropper);
    }
  },
  methods: {
    selectImage(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
          this.$nextTick(() => {
            if (this.cropper) {
              this.cropper.destroy();
            }
            const image = this.$refs.image;
            this.cropper = new Cropper(image, {
              aspectRatio: 1, // Maintain 1:1 aspect ratio
              viewMode: 1,
            });
          });
        };
        reader.readAsDataURL(file);
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
          this.$nextTick(() => {
            if (this.cropper) {
              this.cropper.destroy();
            }
            const image = this.$refs.image;
            this.cropper = new Cropper(image, {
              aspectRatio: 1, // Maintain 1:1 aspect ratio
              viewMode: 1,
              dragMode: 'move', // Allow moving the image
              cropBoxResizable: false, // Prevent resizing of crop box
              cropBoxMovable: false, // Prevent moving of crop box
              autoCropArea: 1, // Ensure the image covers the entire crop box
              ready() {
                // Set the crop box size to 1280x1280 pixels
                const containerData = this.cropper.getContainerData();
                const cropBoxData = {
                  left: (containerData.width - 1280) / 2,
                  top: (containerData.height - 1280) / 2,
                  width: 1280,
                  height: 1280,
                };
                this.cropper.setCropBoxData(cropBoxData);
              },
            });
          });
        };
        reader.readAsDataURL(file);
      }
    },
    cropImage() {
      if (this.cropper) {
        const croppedCanvas = this.cropper.getCroppedCanvas({
          width: 1280,
          height: 1280,
        });
        croppedCanvas.toBlob((blob) => {
          // Perform actions with the cropped image blob, e.g., upload to server
          this.$emit('cropImage', blob);
        });
      }
    },
    close() {
      this.$emit('close');
    },
  },
  beforeDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
};
</script>

<style scoped>
/* Add any additional styling if necessary */
</style>
